import { reactive, watch } from "vue";
//是否允许播放点击音效
const soundState = reactive({
    isClickSoundEnabled: true, // 控制电机btn是否允许播放音效
});
watch(() => soundState.isClickSoundEnabled, () => {
    window.postMessage(JSON.stringify({ type: "carshsound", isopen: soundState.isClickSoundEnabled }), "*");
    window.postMessage(JSON.stringify({ type: "dicesound", isopen: soundState.isClickSoundEnabled }), "*");
    window.postMessage(JSON.stringify({ type: 'limbosound', isopen: soundState.isClickSoundEnabled }), '*');
});
export { soundState };
